import { AiOutlineUser, AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import { useFormik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'

import Input from 'components/input'
import Button from 'components/button'
import { FORGET_PASSWORD_ROUTE, LOGIN_ROUTE } from 'route/appRoutes'
import { Otp } from 'components/Otp'
import { useNavigate } from 'react-router-dom'
import QRCode from 'react-qr-code'
import useTranslationData from '../../translation/hooks/useTranslationData'
import { LoginDataProps, useAuth } from '../context/useAuth'

const LoginForm = () => {
    const { loginUser, checkEmailRegistered, verifyOtp, loading } = useAuth()
    const [step, setStep] = useState<1 | 2 | 3>(1)
    const [qrExist, setQrExist] = useState<boolean>(true)
    const [loadings, setLoadings] = useState<boolean>(false)
    const navigate = useNavigate()
    const [qrCode, setQrCode] = useState<string>('')
    const [otp, setOtp] = useState<[string, string, string, string, string, string]>([
        '',
        '',
        '',
        '',
        '',
        '',
    ])

    const {
        translation: { t },
    } = useTranslationData()

    const formik: FormikProps<LoginDataProps> = useFormik<LoginDataProps>({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Enter the valid email').required('Email is required'),
            password: step === 3 ? Yup.string().required('Password is required') : Yup.string(),
        }),
        onSubmit: async (values, { setSubmitting }: FormikHelpers<LoginDataProps>) => {
            if (step === 1) {
                setLoadings(true)
                checkEmailRegistered({ email: values.email })
                    .then((res: any) => {
                        if (res && res.status === 200) {
                            const emailData = res?.data?.data
                            if (emailData && emailData?.two_factor === true) {
                                setQrCode(emailData?.qr_code)
                            } else {
                                setQrExist(emailData?.two_factor)
                            }
                            setStep(2)
                        }
                    })
                    .catch((error: any) => {
                        setLoadings(false)
                    })
                    .finally(() => setLoadings(false))
            }
            if (step === 2) {
                setLoadings(true)
                verifyOtp({ email: values.email, otp: otp.join('') })
                    .then((res: any) => {
                        if (res && res.status === 200) {
                            if (res?.data?.data?.user === 'verified') {
                                setStep(3)
                                setLoadings(false)
                            }
                        }
                    })
                    .catch((error: any) => {
                        setLoadings(false)
                    })
            }
            if (step === 3) {
                setLoadings(true)
                loginUser(values)
                    .then(() => {
                        setLoadings(false) // Stop loading on success
                    })
                    .catch(() => {
                        setLoadings(false) // Stop loading on failure
                    })
            }
        },
    })

    const [showPass, setShowPass] = useState<boolean>(false)
    return (
        <form className="bg-transparent rounded-md flex flex-col gap-4 w-auto flex-1">
            {step === 2 && qrExist && (
                <div className="text-center font-normal text-sm text-gray-400 mb-5 mt-2">
                    Please use your Authenticator App on your mobile phone to scan the provided QR
                    code. Once the code is scanned, a one-time password (OTP) will be generated and
                    displayed on your phones screen.
                </div>
            )}
            {step === 2 && !qrExist && (
                <div className="text-center font-normal text-sm text-gray-400 mb-5 mt-2">
                    Please use your Authenticator App to view OTP.
                </div>
            )}
            {step === 3 && (
                <div>
                    <h1 className="text-2xl text-center font-medium">{t('api.login_text')}</h1>
                </div>
            )}
            {step === 1 && (
                <>
                    <div className="w-full flex-1">
                        <Input
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            name="email"
                            placeholder="Email"
                            label="Email"
                            rightContent={
                                <span>
                                    <AiOutlineUser />
                                </span>
                            }
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault() // Prevent default form submission
                                    formik.handleSubmit() // Trigger form submission
                                }
                            }}
                        />
                        {formik.touched.email && formik.errors.email && (
                            <p className="text-validation-warning">{formik.errors.email}</p>
                        )}
                    </div>
                    <div className="text-right">
                        <a href={FORGET_PASSWORD_ROUTE} className="link text-sm">
                            {t('api.forgot_password')}
                        </a>
                    </div>

                    <div className="my-2">
                        <Button
                            label="Next"
                            variant="primary"
                            onClick={() => formik.handleSubmit()}
                        />
                    </div>
                </>
            )}
            {step === 2 &&
                (!qrExist ? (
                    <>
                        <p className="text-center">Enter OTP </p>
                        <Otp
                            submitOnEnter={formik.handleSubmit}
                            otp={otp}
                            setOtp={value => setOtp(value)}
                        />
                        <div className="my-2">
                            <Button
                                label="Next"
                                variant="primary"
                                onClick={() => formik.handleSubmit()}
                                disabled={loginUser.isLoading}
                            />
                        </div>
                        <div className="text-center">
                            <a href={LOGIN_ROUTE} className="link text-sm">
                                Back to Login
                            </a>
                        </div>
                    </>
                ) : (
                    <div className="flex flex-col items-center">
                        <p className="text-center mb-4">Scan QR </p>
                        <QRCode value={qrCode} size={150} />

                        <div className="my-2 mt-5">
                            <Button
                                label="Enter OTP"
                                variant="primary"
                                onClick={() => setQrExist(false)}
                                disabled={loginUser.isLoading}
                            />
                        </div>
                    </div>
                ))}

            {step === 3 && (
                <>
                    <div>
                        <Input
                            type={showPass ? 'text' : 'password'}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            name="password"
                            label="Password"
                            rightContent={
                                <span
                                    onClick={() => setShowPass(!showPass)}
                                    className="cursor-pointer"
                                >
                                    {showPass ? <AiFillEyeInvisible /> : <AiFillEye />}
                                </span>
                            }
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault() // Prevent default form submission
                                    formik.handleSubmit() // Trigger form submission
                                }
                            }}
                        />
                        {formik.touched.password && formik.errors.password && (
                            <p className="text-validation-warning">{formik.errors.password}</p>
                        )}
                    </div>
                    <div className="text-right">
                        <a href={FORGET_PASSWORD_ROUTE} className="link text-sm">
                            {t('api.forgot_password')}
                        </a>
                    </div>
                    <div className="my-2">
                        <Button
                            label="Login"
                            variant="primary"
                            onClick={() => formik.handleSubmit()}
                        />
                    </div>
                </>
            )}
        </form>
    )
}

export default LoginForm
