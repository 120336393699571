/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable import/prefer-default-export */
import classNames from 'classnames'
import * as React from 'react'
import { forwardRef } from 'react'
import { FiSearch } from 'react-icons/fi'
import { Button } from './Buttons'

type InputType =
    | 'text'
    | 'password'
    | 'tel'
    | 'number'
    | 'date'
    | 'datetime'
    | 'email'
    | 'file'
    | 'search'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string
    id: string
    name: string
    value?: string | number
    type?: InputType
    placeholder?: string
    errorMessage?: string
    pattern?: string
    min?: number
    max?: number
    disabled?: boolean
    inputClassName?: string
    leftContent?: React.ReactElement
    rightContent?: React.ReactElement
    onBlur?: any
    dataList?: string[]
    list?: string
    onKeyDown?: any
    onPaste?: any
    step?: string | number
    accept?: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const baseClass =
    'w-full input-bordered border-1 border-base-300  placeholder-placeholderColor rounded-md flex items-center gap-2'
export const Input = forwardRef((props: InputProps, ref: any) => {
    const {
        label,
        id,
        name,
        value,
        type = 'text',
        placeholder,
        errorMessage,
        leftContent,
        rightContent,
        min,
        max,
        disabled,
        className,
        inputClassName = '',
        required,
        onChange,
        dataList,
        list,
        pattern,
        onBlur,
        onKeyDown,
        onPaste,
        accept,
        step,
    } = props

    const handleChange = (event: any) => {
        const newValue = event.target.value
        if (type === 'file') {
            onChange(event)
            return
        }
        if (type === 'number' && max !== undefined && Number(newValue) > max) {
            event.target.value = String(max)
        } else if (type === 'number' && min !== undefined && Number(newValue) < min) {
            event.target.value = ''
        } else {
            event.target.value = newValue
        }

        onChange(event)
    }
    return (
        <div
            className={`flex flex-col gap-2 flex-1 w-full ${
                type === 'search' && 'flex-grow'
            } ${className}`}
        >
            <div>
                {label && (
                    <label
                        htmlFor={id}
                        className="label capitalize font-semibold flex justify-start"
                    >
                        {label}
                        {required && <span className="ml-1 text-red-400">*</span>}
                    </label>
                )}
                <div
                    className={classNames(
                        baseClass,
                        type === 'file' && 'cursor-pointer',
                        type === 'search' && 'bg-base-100 flex-grow',
                        errorMessage && 'input-error',
                    )}
                >
                    {leftContent ||
                        (type === 'search' && (
                            <span className="pl-2 text-placeholderColor ">
                                {type === 'search' ? (
                                    <FiSearch className="mx-4" size={24} />
                                ) : (
                                    leftContent
                                )}
                            </span>
                        ))}
                    <input
                        id={id}
                        name={name}
                        type={type}
                        step={step}
                        accept={accept}
                        value={type === 'file' ? [] : value}
                        placeholder={placeholder}
                        onChange={handleChange}
                        pattern={pattern}
                        ref={ref}
                        list={list}
                        className={classNames(
                            'p-2 w-full focus:outline-none  bg-transparent border-gray',
                            type !== 'search' && 'rounded-lg border-2',
                            type === 'file' && 'cursor-pointer hidden',
                            type === 'search' && '',
                            disabled && 'bg-gray-300 cursor-not-allowed',
                            inputClassName,
                        )}
                        onKeyDown={onKeyDown}
                        onPaste={onPaste}
                        min={min}
                        disabled={disabled}
                        max={max}
                        onBlur={onBlur}
                    />
                    <datalist id={list}>
                        {dataList?.map(unit => (
                            <option key={unit} value={unit} label={unit} />
                        ))}
                    </datalist>
                    {type === 'file' && (
                        <div className="rounded-[2px] border-2 p-2 w-full flex gap-2 items-center">
                            <Button
                                onClick={() => {
                                    document.getElementById(id || 'thisInput')?.click()
                                }}
                                size="sm"
                                type="button"
                                label={String(placeholder)}
                            />
                            <p className="overflow-hidden whitespace-nowrap">
                                {(value && String(value)) || ''}
                            </p>
                        </div>
                    )}
                    {rightContent && <span className="pr-2">{rightContent}</span>}
                </div>
                {type === 'file' && (
                    <div>
                        <img id="categoryuploadimage" alt="" />
                    </div>
                )}
            </div>
            {errorMessage && (
                <label htmlFor={id} className="text-red-500">
                    {errorMessage}
                </label>
            )}
        </div>
    )
})
