import { useRef } from 'react'
import { Input } from './Inputs'

interface IOtp {
    otp: [string, string, string, string, string, string]
    setOtp: (otp: [string, string, string, string, string, string]) => void
    error?: string
    submitOnEnter: any
}

export const Otp = (props: IOtp) => {
    const { otp, setOtp, error, submitOnEnter } = props
    const inputRefs = useRef<any>([])

    const handleKeyDown = (e: any, index: number) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus()
        }
        if (e.key === 'Enter') {
            submitOnEnter()
        }
    }

    const handlePaste = (e: any) => {
        const pasteData = e.clipboardData.getData('text/plain').slice(0, 6)
        const newOtp = pasteData.split('').map((digit: string) => digit)
        inputRefs.current[5].focus()
        setOtp(newOtp)
    }

    const handleOTPChange = (e: any, index: number) => {
        const { value } = e.target
        if (value.length <= 1) {
            const newOtp: [string, string, string, string, string, string] = [...otp]
            newOtp[index] = value
            setOtp(newOtp)
            if (value && index < 5) {
                inputRefs.current[index + 1].focus()
            }
        }
        // if(!otp.some(item=>item==='')){
        //   submitOnEnter()
        // }
    }

    return (
        <div className="flex flex-col items-end">
            <div className="w-full flex gap-2 justify-center">
                {otp.map((item, index: number) => (
                    <Input
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        name={`otp-${index}`}
                        value={item}
                        autoComplete="off"
                        onKeyDown={(e: any) => handleKeyDown(e, index)}
                        onPaste={handlePaste}
                        onChange={e => handleOTPChange(e, index)}
                        // eslint-disable-next-line no-return-assign
                        ref={ref => (inputRefs.current[index] = ref)}
                        inputClassName="text-center"
                        maxLength={1}
                        id="otp"
                        type="text"
                        pattern="[a-zA-Z0-9]+"
                    />
                ))}
            </div>
            {error && <p className="text-sm text-left w-full text-error">{error}</p>}
        </div>
    )
}
