import { BtnType, Size } from './BtnType'

export const getVariantClass = (variant: BtnType) => {
    switch (variant) {
        case 'primary':
            return 'btn-primary rounded-lg'
        case 'secondary':
            return 'btn-secondary bg-base-100 border-primary rounded-lg hover:bg-base-300'
        case 'accent':
            return 'btn-accent'
        case 'success':
            return 'btn-success'
        case 'warning':
            return 'btn-warning'
        case 'info':
            return 'btn-info'
        case 'error':
            return 'btn-error'
        case 'ghost':
            return 'btn-ghost hover:text-primary hover:bg-transparent px-0'
        case 'link':
            return 'btn-link'
        default:
            return 'btn-primary'
    }
}

export const getSize = (size: Size) => {
    switch (size) {
        case 'xs':
            return 'btn-xs'
        case 'sm':
            return 'btn-sm'
        case 'md':
            return 'btn-md px-[18px] py-[12px] text-[14px] rounded-[5px]'
        case 'lg':
            return 'btn-lg'
        default:
            return 'btn-md'
    }
}
