/* eslint-disable react/button-has-type */
/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-use-before-define
import classNames from 'classnames'
// eslint-disable-next-line no-use-before-define
import * as React from 'react'
import { BtnType, Size } from './BtnType'
import { getSize, getVariantClass } from './getVariantClass'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    label?: string
    variant?: BtnType
    fullWidth?: boolean
    leftAlign?: boolean
    outline?: boolean
    leftIcon?: React.ReactElement
    rightIcon?: React.ReactElement
    className?: string
    isDisabled?: boolean
    isLoading?: boolean
    onClick?: () => void
    size?: Size
}

const loadingCss = 'cursor-wait disabled loading'
const disabledCss = 'disabled cursor-not-allowed'

export const Button = (props: ButtonProps) => {
    const {
        label,
        variant = 'primary',
        type = 'button',
        size = 'sm',
        fullWidth,
        leftAlign = false,
        leftIcon,
        rightIcon,
        className,
        isLoading,
        isDisabled,
        outline,
        onClick,
    } = props
    return (
        <button
            className={classNames(
                fullWidth ? 'w-full' : 'w-fit',
                size && getSize(size),
                variant && getVariantClass(variant),
                isLoading && loadingCss,
                isDisabled && disabledCss,
                outline && 'btn-outline',
                'btn flex flex-nowrap items-center gap-2 tracking-wider normal-case overflow-hidden whitespace-nowrap',
                leftAlign && 'justify-start',
                className,
            )}
            disabled={isDisabled || isLoading}
            type={type || 'button'}
            onClick={onClick && onClick}
        >
            {leftIcon && <span className="max-w-8">{leftIcon}</span>}
            {label && <span className="text-left whitespace-nowrap">{label}</span>}
            {rightIcon && <span className="w-8">{rightIcon}</span>}
        </button>
    )
}
