import Button from 'components/button'
import { useNavigate } from 'react-router-dom'
import useTranslationData from '../../translation/hooks/useTranslationData'

interface LoginValues {
    email: string
}

const EmailVerification = () => {
    const navigate = useNavigate()
    const {
        translation: { t },
    } = useTranslationData()
    return (
        <div className="w-full">
            <h2 className="text-xl text-center font-medium mb-4">Check your Email</h2>
            <div className="font-light">We have emailed you a link to set your password</div>
            <div className="w-full mt-6">
                <Button
                    label={t('api.back_to_login')}
                    onClick={() => {
                        navigate('/auth/login')
                    }}
                />
            </div>
        </div>
    )
}

export default EmailVerification
